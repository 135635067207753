import Chamodya from "../../assets/images/Committee/Chamodya .svg";
import chandima from "../../assets/images/Committee/Chandima.svg";
import Chathumini from "../../assets/images/Committee/Chathumini.png";
import Darshitha from "../../assets/images/Committee/Darshitha .svg";
import Dinelka from "../../assets/images/Committee/Dinelka.svg";
import DrRohanaUlluwishewa from "../../assets/images/Committee/DrRohanaUlluwishewa.png";
import Durga from "../../assets/images/Committee/Durga .svg";
import Fernando from "../../assets/images/Committee/Fernando.svg";
import Gevin from "../../assets/images/Committee/Gevin.svg";
import GKAJayawardhana from "../../assets/images/Committee/GKAJayawardhana.svg";
import Gunasekara from "../../assets/images/Committee/Gunasekara.svg";
import HBSMKarunarathne from "../../assets/images/Committee/HBSMKarunarathne.svg";
import HGLThenura from "../../assets/images/Committee/HGLThenura.svg";
import HMVSIndrachapa from "../../assets/images/Committee/HMVSIndrachapa.svg";
import Jayawardhana from "../../assets/images/Committee/Jayawardhana .svg";
import Karunarathne from "../../assets/images/Committee/Karunarathne .svg";
import Kulathunga from "../../assets/images/Committee/Kulathunga.svg";
import LPHSLiyanamana from "../../assets/images/Committee/LPHSLiyanamana.svg";
import MrsHMSNiroshani from "../../assets/images/Committee/MrsHMSNiroshani.svg";
import MrTThirumayuran from "../../assets/images/Committee/MrTThirumayuran.png";
import Pasan from "../../assets/images/Committee/Pasan.svg";
import Pathmasiri from "../../assets/images/Committee/Pathmasiri.svg";
import Perera from "../../assets/images/Committee/Perera .svg";
import ProfCharmalieNahallage from "../../assets/images/Committee/ProfCharmalieNahallage.png";
import ProfSudathManjulaAmarasena from "../../assets/images/Committee/ProfSudathManjulaAmarasena.png";
import ProfShiranthaHeenkenda from "../../assets/images/Committee/ProfShiranthaHeenkenda.png";
import ProfSunethraThennkoon from "../../assets/images/Committee/ProfSunethraThennkoon.png";
import DrSanathMahawithanage from "../../assets/images/Committee/DrSanathMahawithanage.jpg";
import randi from "../../assets/images/Committee/Randi.svg";
import Sandamini from "../../assets/images/Committee/Sandamini .svg";
import Thashmil from "../../assets/images/Committee/Thashmil .svg";
import Tirashi from "../../assets/images/Committee/Tirashi.svg";
import Weerakoon from "../../assets/images/Committee/Weerakoon .svg";
import Konara from "../../assets/images/Committee/Konara.jpg";
import Charukshi from "../../assets/images/Committee/Charukshi.jpg";
import RMDSSRajapaksha from "../../assets/images/Committee/RMDSSRajapaksha.jpg";
import GNRathnayake from "../../assets/images/Committee/GNRathnayake.jpg";

export default function Committee() {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="mt-5 text-center">
                <h1>ICLVE Committee</h1>
            </div>


            <section className="m-4 p-4">
                <div className="text-center">
                    <h3 className="mb-5">Conference Advisors</h3>
                </div>
                <div className="d-flex justify-content-center flex-wrap">

                    {/* Card 1 */}
                    {/*<div className="col-lg-4 col-md-6 mb-4">*/}
                    {/*    <div className="card shadow h-100 p-3 m-2">*/}
                    {/*        <img src={ProfSudathManjulaAmarasena} className="card-img-top"*/}
                    {/*             alt="Prof. Sudath Manjula Amarasena"/>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <h5 className="card-title">Prof. Sudath Manjula Amarasena</h5>*/}
                    {/*            <p className="card-text">Director, Career Guidance Unit</p>*/}
                    {/*            <p className="card-text">sudath@sjp.ac.lk</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/* Card 2 */}
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card shadow h-100 p-3 m-2">
                            <img src={ProfShiranthaHeenkenda} className="card-img-top" alt="Prof. Shirantha Heenkenda"/>
                            <div className="card-body">
                                <h5 className="card-title">Prof. Shirantha Heenkenda</h5>
                                <p className="card-text">Dean, Faculty of Humanistic and Social Sciences</p>
                                <p className="card-text">deanfhss@sjp.ac.lk</p>
                            </div>
                        </div>
                    </div>
                    {/* Card 3 */}
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card shadow h-100 p-3 m-2">
                            <img src={ProfCharmalieNahallage} className="card-img-top" alt="Prof. Charmalie Nahallage"/>
                            <div className="card-body">
                                <h5 className="card-title">Prof. Charmalie Nahallage</h5>
                                <p className="card-text">Member, International Center for Multidisciplinary Studies</p>
                                <p className="card-text">chamalie@sjp.ac.lk</p>
                            </div>
                        </div>
                    </div>
                    {/* Card 4 */}
                    {/*<div className="col-lg-4 col-md-6 mb-4">*/}
                    {/*    <div className="card shadow h-100 p-3 m-2">*/}
                    {/*        <img src={DrSanathMahawithanage} className="card-img-top" alt="Advisor 4"/>*/}
                    {/*        <div className="card-body">*/}
                    {/*            <h5 className="card-title">Dr Sanath Mahawithanage</h5>*/}
                    {/*            <p className="card-text">Senior Lecturer, Department of*/}
                    {/*                Biochemistry, Faculty of Medical Sciences , Director of*/}
                    {/*                Center for Mindfulness Research, University of Sri*/}
                    {/*                Jayewardenepura</p>*/}
                    {/*            <p className="card-text">sanath.mahawithanage@gmail.com</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/* Card 5 */}
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card shadow h-100 p-3 m-2">
                            <img src={DrRohanaUlluwishewa} className="card-img-top" alt="DrRohanaUlluwishewa"/>
                            <div className="card-body">
                                <h5 className="card-title">Dr. Rohana Ulluwishewa</h5>
                                <p className="card-text">Retired Professor/Independent
                                    Researcher and Write</p>
                                <p className="card-text">ulluwishewa@xtra.co.nz</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="m-4 p-4">
                <div className="text-center">
                    <h3 className="mb-5">Conference Chair</h3>
                </div>
                <div className="d-flex justify-content-center flex-wrap">
                    {/*/!* Card 1 *!/*/}
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card shadow h-100 p-3 m-2">
                            <img src={ProfSudathManjulaAmarasena} className="card-img-top"
                                 alt="Prof. Sudath Manjula Amarasena"/>
                            <div className="card-body">
                                <h5 className="card-title">Prof. Sudath Manjula Amarasena</h5>
                                <p className="card-text">Director, Career Guidance Unit</p>
                                <p className="card-text">sudath@sjp.ac.lk</p>
                            </div>
                        </div>
                    </div>

                    {/*/!* Card 2 *!/*/}
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card shadow h-100 p-3 m-2">
                            <img src={ProfSunethraThennkoon} className="card-img-top" alt="Prof. Sunethra Thennkoon"/>
                            <div className="card-body">
                                <h5>Prof. Sunethra Thennkoon</h5>
                                <p>Director, International Center for Multidisciplinary Studies, USJ.</p>
                                <p>+94707016295</p>
                                <p>sunethrapk@sjp.ac.lk</p>
                            </div>
                        </div>
                    </div>

                </div>

                {/*------------------------------------Conference Secretaries---------------------------------*/}
                <div className="mt-5 text-center">
                    <h3 className="mb-5">Conference Secretaries</h3>
                </div>
                <div className="d-flex justify-content-center flex-wrap">
                    {/* Secretary 1 */}
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card shadow h-100 p-3 m-2">
                            <img src={MrsHMSNiroshani} className="card-img-top" alt="Mrs. H.M.S. Niroshani"/>
                            <div className="card-body">
                                <h5>Mrs. H.M.S. Niroshani</h5>
                                <p>Career Guidance Unit, University of Sri Jayewardenepura</p>
                                <h6>+94 71700 8793</h6>
                            </div>
                        </div>
                    </div>
                    {/* Secretary 2 */}
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card shadow h-100 p-3 m-2">
                            <img src={MrTThirumayuran} className="card-img-top" alt="Mr. T. Thirumayuran"/>
                            <div className="card-body">
                                <h5>Mr. T. Thirumayuran</h5>
                                <p>Director, Association of Living Values Education International</p>
                                <h6>+94 772849599</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*-------------------------------------Student Organizing Committee----------------------------------*/}

            <div className="container mt-5 text-center">
                <h3 className="mb-5">Student Organizing Committee</h3>

                {/*----------------------------------Advisors------------------------------------*/}

                <h5 className="text-decoration-underline mb-4 mt-4">Advisors</h5>
                <div className="row justify-content-center">
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={chandima} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Mr. C. Ruwanthilaka</h5>
                        <p>Faculty of Applied Sciences</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={randi} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Ms. R. Wathsala</h5>
                        <p>Faculty of Applied Sciences</p>
                    </div>
                </div>

                {/*----------------------------------Project Chairperson------------------------------------*/}

                <h5 className="text-decoration-underline mb-4 mt-4">Project Chairperson</h5>
                <div className="row justify-content-center">
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Chathumini} className="w-100 rounded-5 my-3"/>
                        <h5>Ms. U.A.K. Chathumini</h5>
                        <p>Faculty of Management Studies and Commerce</p>
                    </div>
                </div>

                {/*----------------------------------Co-Chairpersons------------------------------------*/}

                <h5 className="text-decoration-underline mb-4 mt-4">Co-Chairpersons</h5>
                <div className="row justify-content-center">
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Thashmil} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Mr. M. Thashmil </h5>
                        <p>Faculty of Applied Science</p>
                    </div>
                </div>

                {/*-----------------------------------Marketing Crew-------------------------------------*/}

                <h5 className="text-decoration-underline mb-4 mt-4">Marketing Crew</h5>
                <div className="row justify-content-around">
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Pathmasiri} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Mr. D.N.K. Pathmasiri</h5>
                        <p>Faculty of Technology</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Gunasekara} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Ms. K.K. Gunasekara</h5>
                        <p>Faculty of Technology</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Gevin} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Mr. G. Nanayakkara</h5>
                        <p>Faculty of Technology</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Sandamini} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Ms. K.K.I. Sandamini</h5>
                        <p>Faculty of Technology</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Kulathunga} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Mr. H.V.J. Kulathunga</h5>
                        <p>Faculty of Applied Sciences</p>
                    </div>
                </div>

                {/*----------------------------------Human Resource Crew------------------------------------*/}

                <h5 className="text-decoration-underline mb-4 mt-4">Human Resource Crew</h5>
                <div className="row justify-content-center">
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Tirashi} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Ms. R.P.D. Tirashi</h5>
                        <p>Faculty of Management Studies and commerce</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Perera} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>R.M.S.R. Rathnayake</h5>
                        <p>Faculty of Management Studies and commerce</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={GNRathnayake} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Ms.G.N. Rathnayake</h5>
                        <p>Faculty of Technology</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={GKAJayawardhana} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>G.K.A. Jayawardhana</h5>
                        <p>Faculty of Management Studies and commerce</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Darshitha} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Mr. D. Dilshan</h5>
                        <p>Faculty of Applied Sciences</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Weerakoon} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>J.W.A.M. Weerakoon</h5>
                        <p>Faculty of Technology</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Chamodya} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>M.A.S. Chamodya</h5>
                        <p>Faculty of Management Studies and Commerce</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Jayawardhana} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>N.S.A. Jayawardhana</h5>
                        <p>Faculty of Technology</p>
                    </div>
                </div>

                {/*----------------------------------Finance Crew------------------------------------*/}

                <h5 className="text-decoration-underline mb-4 mt-4">Finance Crew</h5>
                <div className="row justify-content-center">
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Pasan} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Mr. P. Katugampala</h5>
                        <p>Faculty of Applied Sciences</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Konara} className="w-100 rounded-5 my-3" alt="Image"/>
                        <h5>K.M.I.D. Konara</h5>
                        <p>Faculty of Management Studies and Commerce</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Durga} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Ms. D. Kavindi</h5>
                        <p>Faculty of Management Studies and Commerce</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Dinelka} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>H.R.G.H. Dinelka</h5>
                        <p> Faculty of Humanities and Social Sciences</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Charukshi} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>K.A.D.D. Charukshi </h5>
                        <p> Faculty of Management Studies and commerce</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={RMDSSRajapaksha} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>R.M.D.S.S. Rajapaksha</h5>
                        <p>Faculty of Applied Sciences</p>
                    </div>
                </div>

                {/*----------------------------------Event Operation Crew------------------------------------*/}

                <h5 className="text-decoration-underline mb-4 mt-4">Event Operation Crew</h5>
                <div className="row justify-content-center">
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={HMVSIndrachapa} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Ms. H.M.V.S. Indrachapa</h5>
                        <p>Faculty of Management Studies and commerce</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Karunarathne} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>K.M.I.D. Karunarathne</h5>
                        <p>Faculty of Urban and Aquatic Bioresources</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={Fernando} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Mr. P.K. Fernando</h5>
                        <p>Faculty of Management Studies and Commerce</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={LPHSLiyanamana} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Ms. L.P.H.S. Liyanamana</h5>
                        <p>Faculty of Technology</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={HBSMKarunarathne} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>H.B.S.M. Karunarathne</h5>
                        <p>Faculty of Applied Sciences</p>
                    </div>
                    <div
                        className="col-lg-2 col-md-4 col-sm-6 m-3 shadow rounded-5 d-flex flex-column align-items-center text-center">
                        <img src={HGLThenura} className="w-100 rounded-5 my-3" alt="IMG"/>
                        <h5>Mr. H.G.L. Thenura</h5>
                        <p>Faculty of Applied Sciences</p>
                    </div>
                </div>

            </div>
        </div>
    );
}